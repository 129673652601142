export const Currency = "$";
export const PlanTypeBasic = "BASIC";
export const PlanTypeSmart = "SMART";
export const AAAMembersDiscountOnMonitoring = 5;
export const BasicMonthlyMonitoring = 24.99;
export const SmartMonthlyMonitoring = 34.99;
export const BasicInstallationPro = 584.93;
export const SmartInstallationPro = 834.99;
export const BasicInstallationSelf = 584.93;
export const SmartInstallationSelf = 764.91;
export const AutoInsuranceDownPayment = 438.0;
export const HomeInsuranceDownPayment = 625.0;
export const EntrySensors = 29.99;
export const IndoorCam = 99.99;
export const OutdoorCam = 249.99;
export const DoorbellCam = 229.99;
export const SmartLock = 239.99;
export const PlanType = "PlanType";
export const AAAMember = "Member";
export const AutoInsurance = "AutoInsurance";
export const HomeInsurance = "HomeInsurance";
//CA Auto insurance Discount
export const AutoInsuranceMembershipDiscount = 0.05; //5%
export const AutoInsuranceDisHO3DP3 = 0.127;
export const AutoInsuranceDisHO4HO6 = 0.075;
export const AutoInsuranceDisLifeinsu = 0.05;

//Auto insurance Discount
export const AutoInsuranceMembershipDiscountOtr = 0.05; //5%
export const AutoInsuranceDisHO3DP3Otr = 0.1;
export const AutoInsuranceDisHO4HO6Otr = 0.0;
export const AutoInsuranceDisLifeinsuOtr = 0.05;
// //All Other Home Insurance Discounts
// export const HomeInsuranceMembershipDiscount = 0.05;
// export const HomeInsuranceDisHO3DP3 = 0.1;
// export const HomeInsuranceDisHO4HO6 = 0.05;
// export const HomeInsuranceDisLifeinsu = 0.125;
//All Other Home Insurance Discounts
export const HomeInsuranceMembershipDiscountAllother = 0.05;
export const HomeInsuranceDisAllOther = 0.08;
export const HomeInsuranceDisLifeinsu = 0.125;
//CA Home Insurance Discounts
export const HomeInsuranceMembershipDiscountCA = 0.05;
export const HomeInsuranceDisCAHO3HO6 = 0.1;
export const HomeInsuranceDisCADP3 = 0.15;
export const HomeInsuranceDisCAHO4 = 0;

//New Installation Prices
export const InstallationPRO = 175;
export const InstallationSELF = 29.99;

//Tooltip Messages
export const AutoInsuranceToopTip = "AutoInsurance";
export const HomeInsuranceToopTip = "HomeInsurance";
export const SmartHomeSecurityMonitoryingToolTip = "HomeInsurance";
export const HomeSecurityEquimpmentandInstallationToolTip =
  "HomeSecurityEquimpmentandInstallationToolTip";
// general information error messages
export const DownloadQuote = "Download Quote";
export const EmailQuote = "Email Quote";
export const Sendingmail = "Sending...";
export const Messages = "Error or success messages";
export const ErrorMessage = "Fill all mandatory fields";
export const SentMail = "E-Mail sent successfully";
export const Downloaded = "PDF Downloaded successfully";
export const Error = "Please try again";
export const Downloading = "Downloading...";
export const EmailValidation = "Please add correct Email Address";
