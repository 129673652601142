import React, { Component } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import "../BundleQuoteComponent/BundleQuote.css";
import CurrencyInput from "react-currency-input-field";
import * as constant from "../../constants";
import "../FooterComponent/Footer.css";
import PDF from "../PDFComponent/PDF";
// import EmailTemplate from "../EmailComponent/Email";
import EmailSupport from "../EmailTemplateComponent/Support";

import jsPDF from "jspdf";
//import axios from "axios";
import domtoimage from "dom-to-image";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumberFormat from "react-number-format";
toast.configure();
let base64data;
const symbolsArr = ["e", "E", "+", "-"];
export default class BundleQuoteTool extends Component {
  componentDidMount() {}
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone1: "",
      email: "",
      isMember: "Yes",
      membershipID: "",
      //Home
      type: "",
      squareFootage: "",
      level: "",
      numberofDoors: "",
      numberofWindows: "",
      indoorcam: 0,
      outdoorcam: 0,
      doorbell: 0,
      smartlock: 0,
      //Insurance
      isCurrentAAAInsuranceCustomer: "Yes",
      autoInsuranceQuote: "",
      homeInsuranceQuote: "",
      autoInsuranceQuoteMonthly: "",
      homeInsuranceQuoteMonthly: "",
      autoInsuranceQuoteDownpayment: "",
      homeInsuranceQuoteDownpayment: "",
      // Smart Home Security
      smartHomeQuotePlanType: "",
      smartHomeQuoteInstallType: "",
      smartHomeQuoteMonitoring: "",
      smartHomeQuoteInstallation: "",
      //
      //package prising
      homeSecurityEquipmentAndInsta: "",
      monthlyTotalCostListPrice: "",
      autoInsuranceBundle: "",
      homeInsuranceBundle: "",
      homeInsuranceSecurity: "",
      monthlyTotalDiscount: "",
      monthlyTotalNetPrice: "",
      //
      base: "",
      showDownpayment: false,
      Downloading: constant.DownloadQuote,
      Emailsent: constant.EmailQuote,
    };
    this.handleChange = this.handleChange.bind(this);
    this.generatePdf = this.generatePdf.bind(this);
  }
  handleChange(e, type) {
    const name = e !== undefined ? e.target.name : "";
    const value = e !== undefined ? e.target.value : "";
    this.setState({ [name]: value }, () => console.log());
    if (type !== undefined && type === constant.PlanType) {
      this.planCalculation(
        value,
        this.state.isMember,
        this.state.smartHomeQuoteInstallType
      );
    } else if (type !== undefined && type === constant.AAAMember) {
      this.isAAAmemberDownpayment(value);
    }
    if (name === "isMember") {
      this.planCalculation(
        this.state.smartHomeQuotePlanType,
        value,
        this.state.smartHomeQuoteInstallType
      );
    }

    if (name === "smartHomeQuoteInstallType") {
      this.planCalculation(
        this.state.smartHomeQuotePlanType,
        this.state.isMember,
        value
      );
    }

    if (name === "isMember" && value === "No") {
      document.getElementsByName("membershipID")[0].value = "";
    }

    if (
      name === "indoorcam" ||
      name === "outdoorcam" ||
      name === "doorbell" ||
      name === "smartlock"
    ) {
      if (value > 0) {
        this.setState({ smartHomeQuotePlanType: "SMART" });
        this.planCalculation(
          this.state.smartHomeQuotePlanType,
          this.state.isMember
        );
      }
      // RESET TO BASIC
      setTimeout(() => {
        if (
          Number(this.state.indoorcam) === 0 &&
          Number(this.state.outdoorcam) === 0 &&
          Number(this.state.smartlock) === 0 &&
          Number(this.state.doorbell) === 0
        ) {
          this.setState({ smartHomeQuotePlanType: "BASIC" });
          this.planCalculation(
            this.state.smartHomeQuotePlanType,
            this.state.isMember
          );
        }
      }, 500);
    }
    if (name === "type" || name === "state") {
      setTimeout(() => {
        this.bundleAndSecurityCalculations();
      }, 500);
    }
  }

  //Add currency Symbol to amount
  attachCurrency(amount) {
    if (amount !== "" && amount !== 0 && amount !== undefined)
      return constant.Currency + Number(amount).toFixed(2);
  }

  bundleAndSecurityCalculations() {
    // if (this.state.autoInsuranceQuote === "") {
    //   return false;
    // }
    var _type = this.state.type;
    var zip = this.state.state;
    var autoInsurance = "",
      homeinsurancesecurity = "",
      homeinsurance = "";

    switch (_type) {
      case "HO3":
        autoInsurance =
          zip === "CA"
            ? constant.AutoInsuranceDisHO3DP3 *
              this.state.autoInsuranceQuoteMonthly
            : constant.AutoInsuranceDisHO3DP3Otr *
              this.state.autoInsuranceQuoteMonthly;

        // autoInsurance =
        //   constant.AutoInsuranceDisHO3DP3 *
        //   this.state.autoInsuranceQuoteMonthly;
        this.setState({
          autoInsuranceBundle: autoInsurance,
        });
        homeinsurancesecurity =
          zip === "CA"
            ? constant.HomeInsuranceDisCAHO3HO6 *
              this.state.homeInsuranceQuoteMonthly
            : constant.HomeInsuranceDisAllOther *
              this.state.homeInsuranceQuoteMonthly;
        this.setState({
          homeInsuranceSecurity: homeinsurancesecurity,
        });
        break;
      case "DP3":
        autoInsurance =
          zip === "CA"
            ? constant.AutoInsuranceDisHO3DP3 *
              this.state.autoInsuranceQuoteMonthly
            : constant.AutoInsuranceDisHO3DP3Otr *
              this.state.autoInsuranceQuoteMonthly;
        // autoInsurance =
        //   constant.AutoInsuranceDisHO3DP3 *
        //   this.state.autoInsuranceQuoteMonthly;
        this.setState({
          autoInsuranceBundle: autoInsurance,
        });

        homeinsurancesecurity =
          zip === "CA"
            ? constant.HomeInsuranceDisCADP3 *
              this.state.homeInsuranceQuoteMonthly
            : constant.HomeInsuranceDisAllOther *
              this.state.homeInsuranceQuoteMonthly;
        this.setState({
          homeInsuranceSecurity: homeinsurancesecurity,
        });
        break;
      case "HO6":
        autoInsurance =
          zip === "CA"
            ? constant.AutoInsuranceDisHO4HO6 *
              this.state.autoInsuranceQuoteMonthly
            : constant.AutoInsuranceDisHO4HO6Otr *
              this.state.autoInsuranceQuoteMonthly;
        // autoInsurance =
        //   constant.AutoInsuranceDisHO4HO6 *
        //   this.state.autoInsuranceQuoteMonthly;
        this.setState({
          autoInsuranceBundle: autoInsurance,
        });
        homeinsurancesecurity =
          zip === "CA"
            ? constant.HomeInsuranceDisCAHO3HO6 *
              this.state.homeInsuranceQuoteMonthly
            : constant.HomeInsuranceDisAllOther *
              this.state.homeInsuranceQuoteMonthly;
        this.setState({
          homeInsuranceSecurity: homeinsurancesecurity,
        });
        break;
      case "HO4":
        autoInsurance =
          zip === "CA"
            ? constant.AutoInsuranceDisHO4HO6 *
              this.state.autoInsuranceQuoteMonthly
            : constant.AutoInsuranceDisHO4HO6Otr *
              this.state.autoInsuranceQuoteMonthly;
        // autoInsurance =
        //   constant.AutoInsuranceDisHO4HO6 *
        //   this.state.autoInsuranceQuoteMonthly;
        this.setState({
          autoInsuranceBundle: autoInsurance,
        });

        homeinsurancesecurity =
          zip === "CA"
            ? constant.HomeInsuranceDisCAHO4 *
              this.state.homeInsuranceQuoteMonthly
            : constant.HomeInsuranceDisAllOther *
              this.state.homeInsuranceQuoteMonthly;
        this.setState({
          homeInsuranceSecurity: homeinsurancesecurity,
        });
        break;
      default:
        break;
    }

    homeinsurance =
      constant.HomeInsuranceDisLifeinsu * this.state.homeInsuranceQuoteMonthly;

    this.setState({
      homeInsuranceBundle: homeinsurance,
    });
    this.setState({
      monthlyTotalDiscount: Number(
        homeinsurancesecurity + homeinsurance + autoInsurance
      ),
    });
  }

  insuranceClaculation(value, type) {
    if (value === undefined && type === constant.AutoInsurance) {
      this.setState({ autoInsuranceQuote: "" });
      this.setState({ autoInsuranceQuoteMonthly: "" });
      this.setState({ autoInsuranceQuoteDownpayment: "" });
    } else if (
      value !== undefined &&
      value !== "" &&
      type === constant.AutoInsurance
    ) {
      this.setState({ autoInsuranceQuote: value });
      this.setState({
        autoInsuranceQuoteMonthly: parseFloat(value) / 12,
      });
      if (this.state.isCurrentAAAInsuranceCustomer === "No") {
        this.setState({
          autoInsuranceQuoteDownpayment: constant.AutoInsuranceDownPayment,
        });
      }
    } else if (value === undefined && type === constant.HomeInsurance) {
      this.setState({ homeInsuranceQuote: "" });
      this.setState({ homeInsuranceQuoteMonthly: "" });
      this.setState({ homeInsuranceQuoteDownpayment: "" });
    } else if (
      value !== undefined &&
      value !== "" &&
      type === constant.HomeInsurance
    ) {
      this.setState({ homeInsuranceQuote: value });
      this.setState({
        homeInsuranceQuoteMonthly: parseFloat(value) / 12,
      });
      if (this.state.isCurrentAAAInsuranceCustomer === "No") {
        this.setState({
          homeInsuranceQuoteDownpayment: constant.HomeInsuranceDownPayment,
        });
      }
    }
    // Discount Calculations
    setTimeout(() => {
      this.bundleAndSecurityCalculations();
    }, 500);
    this.setState({
      monthlyTotalCostListPrice:
        Number(this.state.autoInsuranceQuoteMonthly) +
        Number(this.state.homeInsuranceQuoteMonthly) +
        Number(this.state.smartHomeQuoteMonitoring) +
        Number(this.state.homeSecurityEquipmentAndInsta),
    });
  }

  isAAAmemberDownpayment = (isMember) => {
    if (isMember === "Yes") {
      this.setState({ showDownpayment: false });
      this.setState({ autoInsuranceQuoteDownpayment: "" });
      this.setState({ homeInsuranceQuoteDownpayment: "" });
    } else if (isMember === "No") {
      this.setState({ showDownpayment: true });
      if (this.state.homeInsuranceQuote !== "") {
        this.setState({
          homeInsuranceQuoteDownpayment: constant.HomeInsuranceDownPayment,
        });
      }
      if (this.state.autoInsuranceQuote !== "") {
        this.setState({
          autoInsuranceQuoteDownpayment: constant.AutoInsuranceDownPayment,
        });
      }
    }
  };

  planCalculation(planType, isMember, installType) {
    if (installType === "") return false;
    let discount = 0;
    let noOfEntrySensors = this.state.numberofDoors * constant.EntrySensors;
    let noOfIndoorcam = this.state.indoorcam * constant.IndoorCam;
    // for smart 1 added by defaut in package
    // MONITORING CALCULATIONS
    let noOfOutdoorcam = 0;
    if (planType !== "" && planType === constant.PlanTypeSmart) {
      noOfOutdoorcam =
        (this.state.outdoorcam > 1 ? this.state.outdoorcam - 1 : 0) *
        constant.OutdoorCam;
    } else {
      noOfOutdoorcam = this.state.outdoorcam * constant.OutdoorCam;
    }
    let noOfDoorbell = this.state.doorbell * constant.DoorbellCam;
    let noOfSmartLock = this.state.smartlock * constant.SmartLock;
    if (isMember === "Yes") {
      discount = constant.AAAMembersDiscountOnMonitoring;
      if (planType !== "" && planType === constant.PlanTypeBasic) {
        this.setState({
          smartHomeQuoteMonitoring: constant.BasicMonthlyMonitoring - discount,
        });
      } else if (planType !== "" && planType === constant.PlanTypeSmart) {
        this.setState({
          smartHomeQuoteMonitoring: constant.SmartMonthlyMonitoring - discount,
        });
      }
    } else if (isMember === "No") {
      if (planType !== "" && planType === constant.PlanTypeBasic) {
        this.setState({
          smartHomeQuoteMonitoring: constant.BasicMonthlyMonitoring,
        });
      } else if (planType !== "" && planType === constant.PlanTypeSmart) {
        this.setState({
          smartHomeQuoteMonitoring: constant.SmartMonthlyMonitoring,
        });
      }
      //this.setState({ showDownpayment: true });
      if (this.state.homeInsuranceQuote !== "") {
        this.setState({
          homeInsuranceQuoteDownpayment: constant.HomeInsuranceDownPayment,
        });
      }
      if (this.state.autoInsuranceQuote !== "") {
        this.setState({
          autoInsuranceQuoteDownpayment: constant.AutoInsuranceDownPayment,
        });
      }
    }

    // INSTALLATION CALCULATIONS
    var basicInstallation = 0;
    var smartInstallation = 0;
    if (
      planType !== "" &&
      planType === constant.PlanTypeBasic &&
      installType === "PRO"
    ) {
      basicInstallation =
        constant.BasicInstallationPro +
        (noOfDoorbell +
          noOfIndoorcam +
          noOfOutdoorcam +
          noOfSmartLock +
          noOfEntrySensors +
          constant.InstallationPRO);
      this.setState({
        smartHomeQuoteInstallation: basicInstallation,
        homeSecurityEquipmentAndInsta: Number(basicInstallation / 36),
      });
    } else if (
      planType !== "" &&
      planType === constant.PlanTypeBasic &&
      installType === "SELF"
    ) {
      basicInstallation =
        constant.BasicInstallationSelf +
        (noOfDoorbell +
          noOfIndoorcam +
          noOfOutdoorcam +
          noOfSmartLock +
          noOfEntrySensors +
          constant.InstallationSELF);
      this.setState({
        smartHomeQuoteInstallation: basicInstallation,
        homeSecurityEquipmentAndInsta: Number(basicInstallation / 36),
      });
    } else if (
      planType !== "" &&
      planType === constant.PlanTypeSmart &&
      installType === "PRO"
    ) {
      smartInstallation =
        constant.SmartInstallationPro +
        (noOfDoorbell +
          noOfIndoorcam +
          noOfOutdoorcam +
          noOfSmartLock +
          noOfEntrySensors +
          constant.InstallationPRO);
      this.setState({
        smartHomeQuoteInstallation: smartInstallation,
        homeSecurityEquipmentAndInsta: Number(smartInstallation / 36),
      });
    } else if (
      planType !== "" &&
      planType === constant.PlanTypeSmart &&
      installType === "SELF"
    ) {
      smartInstallation =
        constant.SmartInstallationSelf +
        (noOfDoorbell +
          noOfIndoorcam +
          noOfOutdoorcam +
          noOfSmartLock +
          noOfEntrySensors +
          constant.InstallationSELF);
      this.setState({
        smartHomeQuoteInstallation: smartInstallation,
        homeSecurityEquipmentAndInsta: Number(smartInstallation / 36),
      });
    } else {
      this.setState({ smartHomeQuoteMonitoring: "" });
      this.setState({
        smartHomeQuoteInstallation: "",
        homeSecurityEquipmentAndInsta: "",
      });
    }

    // if (planType !== "" && planType === constant.PlanTypeBasic) {
    //   var basicInstallation =
    //     constant.BasicInstallation +
    //     (noOfDoorbell +
    //       noOfIndoorcam +
    //       noOfOutdoorcam +
    //       noOfSmartLock +
    //       noOfEntrySensors);
    //   this.setState({
    //     smartHomeQuoteInstallation: basicInstallation,
    //     homeSecurityEquipmentAndInsta: Number(basicInstallation / 12),
    //   });
    // } else if (planType !== "" && planType === constant.PlanTypeSmart) {
    //   var smartInstallation =
    //     constant.SmartInstallation +
    //     (noOfDoorbell +
    //       noOfIndoorcam +
    //       noOfOutdoorcam +
    //       noOfSmartLock +
    //       noOfEntrySensors);
    //   this.setState({
    //     smartHomeQuoteInstallation: smartInstallation,
    //     homeSecurityEquipmentAndInsta: Number(smartInstallation / 12),
    //   });
    // } else {
    //   this.setState({ smartHomeQuoteMonitoring: "" });
    //   this.setState({
    //     smartHomeQuoteInstallation: "",
    //     homeSecurityEquipmentAndInsta: "",
    //   });
    // }

    this.setState({
      monthlyTotalCostListPrice:
        Number(this.state.autoInsuranceQuoteMonthly) +
        Number(this.state.homeInsuranceQuoteMonthly) +
        Number(this.state.smartHomeQuoteMonitoring) +
        Number(this.state.homeSecurityEquipmentAndInsta),
    });
  }
  validate = () => {
    let isValid = true;

    if (this.state.email === null || this.state.email === "") {
      document.getElementsByName("email")[0].classList.add("error-border");
      isValid = false;
    } else {
      document.getElementsByName("email")[0].classList.remove("error-border");
    }

    if (document.getElementsByName("email")[0].value !== "") {
      if (
        document.getElementsByName("email")[0].value.includes(".") === false ||
        document.getElementsByName("email")[0].value.includes("@") === false
      ) {
        document.getElementsByName("email")[0].classList.add("error-border");
        toast.error(constant.EmailValidation);
        isValid = false;
        return false;
      } else {
        document.getElementsByName("email")[0].classList.remove("error-border");
      }
    }

    if (
      (this.state.membershipID === null || this.state.membershipID === "") &&
      this.state.isMember === "Yes"
    ) {
      document
        .getElementsByName("membershipID")[0]
        .classList.add("error-border");
      isValid = false;
    } else
      document
        .getElementsByName("membershipID")[0]
        .classList.remove("error-border");
    if (this.state.name === null || this.state.name === "") {
      document.getElementsByName("name")[0].classList.add("error-border");
      isValid = false;
    } else
      document.getElementsByName("name")[0].classList.remove("error-border");
    if (this.state.type === null || this.state.type === "") {
      document.getElementsByName("type")[0].classList.add("error-border");
      isValid = false;
    } else
      document.getElementsByName("type")[0].classList.remove("error-border");
    if (this.state.state === null || this.state.state === "") {
      document.getElementsByName("state")[0].classList.add("error-border");
      isValid = false;
    } else
      document.getElementsByName("state")[0].classList.remove("error-border");
    if (this.state.phone1 === null || this.state.phone1 === "") {
      document.getElementsByName("phone1")[0].classList.add("error-border");
      isValid = false;
    } else
      document.getElementsByName("phone1")[0].classList.remove("error-border");
    if (this.state.isMember === null || this.state.isMember === "") {
      document.getElementsByName("isMember")[0].classList.add("error-border");
      isValid = false;
    } else
      document
        .getElementsByName("isMember")[0]
        .classList.remove("error-border");
    if (
      this.state.homeInsuranceQuote === null ||
      this.state.homeInsuranceQuote === ""
    ) {
      document
        .getElementsByName("homeInsuranceQuote")[0]
        .classList.add("error-border");
      isValid = false;
    } else
      document
        .getElementsByName("homeInsuranceQuote")[0]
        .classList.remove("error-border");
    if (
      this.state.smartHomeQuotePlanType === null ||
      this.state.smartHomeQuotePlanType === ""
    ) {
      document
        .getElementsByName("smartHomeQuotePlanType")[0]
        .classList.add("error-border");
      isValid = false;
    } else
      document
        .getElementsByName("smartHomeQuotePlanType")[0]
        .classList.remove("error-border");

    if (
      this.state.smartHomeQuoteInstallType === null ||
      this.state.smartHomeQuoteInstallType === ""
    ) {
      document
        .getElementsByName("smartHomeQuoteInstallType")[0]
        .classList.add("error-border");
      isValid = false;
    } else
      document
        .getElementsByName("smartHomeQuoteInstallType")[0]
        .classList.remove("error-border");

    if (!isValid) {
      toast.error(constant.ErrorMessage);
    }
    return isValid;
  };
  pdfjs = (e) => {
    var isvalid = this.validate();
    if (!isvalid) {
      return false;
    }
    this.setState({ Downloading: constant.Downloading });
    this.generatePdf(false);
  };
  sendMail = (e) => {
    var isvalid = this.validate();
    if (!isvalid) {
      return false;
    }
    this.setState({ Emailsent: constant.Sendingmail });
    this.generatePdf(true);
  };

  getFullYear(year) {
    var new_year = "";
    switch (year) {
      case 1:
        new_year = "JAN";
        break;
      case 2:
        new_year = "FEB";
        break;
      case 3:
        new_year = "MAR";
        break;
      case 4:
        new_year = "APR";
        break;
      case 5:
        new_year = "MAY";
        break;
      case 6:
        new_year = "JUN";
        break;
      case 7:
        new_year = "JUL";
        break;
      case 8:
        new_year = "AUG";
        break;
      case 9:
        new_year = "SEP";
        break;
      case 10:
        new_year = "OCT";
        break;
      case 11:
        new_year = "NOV";
        break;
      case 12:
        new_year = "DEC";
        break;
      default:
        break;
    }
    return new_year;
  }
  generatePdf = (isMail) => {
    const input = document.getElementById("page");
    const options = { background: "white", height: 1123, width: 794 };
    domtoimage.toJpeg(input, options).then((dataUrl) => {
      //Initialize JSPDF
      const doc = new jsPDF("p", "pt", "a4", true);
      //Add image Url to PDF
      //you can choose between NONE, FAST, MEDIUM and SLOW, whichever suits you best.
      doc.addImage(dataUrl, "JPEG", 0, 0, 0, 0, 0, "MEDIUM");
      var _today = new Date();
      var name =
        this.state.name +
        " " +
        "AAA Savings Estimate " +
        _today.getFullYear() +
        " " +
        this.getFullYear(_today.getMonth() + 1) +
        " " +
        _today.getDate() +
        ".pdf";
      if (isMail) {
        let email = this.state.email;
        let dearName = this.state.name;
        let angentName = "Agent";
        let template = document
          .getElementById("emailTemplate")
          .innerHTML.toString();
        template = template
          .replace("_DearName_", dearName)
          .replace("_AgentName_", angentName);
        var out = doc.output("blob");
        var reader = new FileReader();
        reader.readAsDataURL(out);
        reader.onloadend = function () {
          base64data = reader.result;
          fetch(
            "https://aaabundlequoteapi.azurewebsites.net/api/sendNotification",
            {
              // Adding method type
              method: "POST",
              mode: "cors",
              cache: "default",
              headers: {
                //"Content-Type": "application/json",
                "Content-type": "application/json; charset=UTF-8",
              },
              // Adding body or contents to send
              body: JSON.stringify({
                toemail: email,
                subject: "Your AAA savings are here",
                emailbody: template,
                content: base64data,
                filemame: name,
              }),
            }
          )
            .then((response) => response)
            // Displaying results to console
            .then((json) => {
              toast.success(constant.SentMail);
            });
        };
        this.setState({ Emailsent: constant.EmailQuote });
      }
      // }
      else {
        doc.save(name);
        toast.success(constant.Downloaded);
        this.setState({ Downloading: constant.DownloadQuote });
      }
    });
  };

  render() {
    return (
      <section>
        <div className="aaa-bundle-title d-xs-block d-xl-none text-center">
          Bundle Savings Estimate Tool
        </div>
        <div className="aaa-bundle-discription"></div>
        <Form>
          <Row>
            <Col xl={7} lg={12} md={12} xs={12}>
              <Col className="aaa-rectangle first-row" xs={12} md={12}>
                <h4>Member</h4>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Name<span className="req">*</span>
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      placeholder=""
                      name="name"
                      id="cName"
                      value={this.state.name}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Address
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      placeholder=""
                      id="cAddress"
                      name="address"
                      value={this.state.address}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Address 2
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      placeholder=""
                      id="cAddress2"
                      name="address2"
                      value={this.state.address2}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    City
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      placeholder=""
                      id="cCity"
                      name="city"
                      value={this.state.city}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                  <Form.Label column sm="1">
                    State<span className="req">*</span>
                  </Form.Label>
                  <Col sm="2">
                    <Form.Control
                      as="select"
                      value={this.state.state}
                      name="state"
                      onChange={(e) => this.handleChange(e)}
                    >
                      <option value=""></option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AR">AR</option>
                      <option value="AZ">AZ</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="IA">IA</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="MA">MA</option>
                      <option value="MD">MD</option>
                      <option value="ME">ME</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MO">MO</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="NC">NC</option>
                      <option value="ND">ND</option>
                      <option value="NE">NE</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NV">NV</option>
                      <option value="NY">NY</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WI">WI</option>
                      <option value="WV">WV</option>
                      <option value="WY">WY</option>
                    </Form.Control>
                  </Col>
                  <Form.Label column sm="1">
                    Zip
                  </Form.Label>
                  <Col sm="2">
                    <Form.Control
                      type="number"
                      id="cZip"
                      name="zip"
                      onKeyDown={(e) =>
                        symbolsArr.includes(e.key) && e.preventDefault()
                      }
                      value={this.state.zip}
                      onChange={(e) => this.handleChange(e)}
                      onInput={(e) => {
                        if (e.target.value.length > 5)
                          e.target.value = e.target.value.slice(0, 5);
                      }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column sm="2">
                    Phone#<span className="req">*</span>
                  </Form.Label>
                  <Col sm="4">
                    <NumberFormat
                      format="(###) ###-####"
                      mask=""
                      placeholder=""
                      name="phone1"
                      className="form-control"
                      value={this.state.phone1}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                  <Form.Label column sm="2">
                    Email<span className="req">*</span>
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      placeholder=""
                      name="email"
                      value={this.state.email}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="label-member" column sm="2" xs="4">
                    Member<span className="req">*</span>
                  </Form.Label>
                  <Col sm="3" xs="8" className="aaa-radio">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      name="isMember"
                      id="cMemberYes"
                      checked={this.state.isMember === "Yes"}
                      value="Yes"
                      onChange={(e) => this.handleChange(e)}
                    />
                    <Form.Check
                      type="radio"
                      label="No"
                      name="isMember"
                      id="cMemberNo"
                      value="No"
                      checked={this.state.isMember === "No"}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                  <Form.Label column sm="3">
                    Membership ID
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      disabled={this.state.isMember === "No"}
                      id="cMemberYes"
                      type="number"
                      name="membershipID"
                      onKeyDown={(e) =>
                        symbolsArr.includes(e.key) && e.preventDefault()
                      }
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Col>

            <Col xl={5} lg={12} md={12} xs={12}>
              <Col className="aaa-rectangle first-row" xs={12} md={12}>
                <h4>Home</h4>
                <Form.Group as={Row} controlId="formtype">
                  <Form.Label column sm="3">
                    Type:<span className="req">*</span>
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      as="select"
                      name="type"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.type}
                    >
                      <option value=""></option>
                      <option value="HO3">Home Owner(HO3)</option>
                      <option value="HO6">Condo Owner(HO6)</option>
                      <option value="HO4">Renter (HO4)</option>
                      <option value="DP3">Renter (DP3)</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formsqlarefootage">
                  <Form.Label column sm="6">
                    Square Footage:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="squareFootage"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.squareFootage}
                    >
                      <option value=""></option>
                      <option value="Under600Sqft">600 or under Sq Ft</option>
                      <option value="601-900Sqft">601 - 900 Sq Ft</option>
                      <option value="901-1800Sqft">901- 1800 Sq Ft</option>
                      <option value="1801-2500Sqft">1801 - 2500 Sq Ft</option>
                      <option value="2501-4000Sqft">2501 - 4000 Sq Ft</option>
                      <option value="4001-6000Sqft">4001 - 6000 Sq Ft</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formsinglemultiple">
                  <Form.Label column sm="6">
                    Single or Multi Level:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="level"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.level}
                    >
                      <option value=""></option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formnumberofdoor">
                  <Form.Label column sm="6">
                    Number of Doors & Windows:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="numberofDoors"
                      onChange={(e) => {
                        this.handleChange(e);
                        setTimeout(() => {
                          this.planCalculation(
                            this.state.smartHomeQuotePlanType,
                            this.state.isMember,
                            this.state.smartHomeQuoteInstallType
                          );
                        }, 600);
                      }}
                      value={this.state.numberofDoors}
                    >
                      <option value=""></option>
                      <option value="0">4</option>
                      <option value="1">5</option>
                      <option value="2">6</option>
                      <option value="3">7</option>
                      <option value="4">8</option>
                      <option value="5">9</option>
                      <option value="6">10</option>
                      <option value="7">11</option>
                      <option value="8">12</option>
                      <option value="9">13</option>
                      <option value="10">14</option>
                      <option value="11">15</option>
                      <option value="12">16</option>
                      <option value="13">17</option>
                      <option value="14">18</option>
                      <option value="15">19</option>
                      <option value="16">20</option>
                      <option value="17">21</option>
                      <option value="18">22</option>
                      <option value="19">23</option>
                      <option value="20">24</option>
                      <option value="21">25</option>
                      <option value="22">26</option>
                      <option value="23">27</option>
                      <option value="24">28</option>
                      <option value="25">29</option>
                      <option value="26">30</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formindoorcam">
                  <Form.Label column sm="6">
                    Indoor cam:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="indoorcam"
                      onChange={(e) => {
                        this.handleChange(e);
                        setTimeout(() => {
                          this.planCalculation(
                            this.state.smartHomeQuotePlanType,
                            this.state.isMember,
                            this.state.smartHomeQuoteInstallType
                          );
                        }, 600);
                      }}
                      value={this.state.indoorcam}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formoutdoorcam">
                  <Form.Label column sm="6">
                    Outdoor cam:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="outdoorcam"
                      onChange={(e) => {
                        this.handleChange(e);
                        setTimeout(() => {
                          this.planCalculation(
                            this.state.smartHomeQuotePlanType,
                            this.state.isMember,
                            this.state.smartHomeQuoteInstallType
                          );
                        }, 600);
                      }}
                      value={this.state.outdoorcam}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formdoorbellcam">
                  <Form.Label column sm="6">
                    Doorbell cam:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="doorbell"
                      onChange={(e) => {
                        this.handleChange(e);
                        setTimeout(() => {
                          this.planCalculation(
                            this.state.smartHomeQuotePlanType,
                            this.state.isMember,
                            this.state.smartHomeQuoteInstallType
                          );
                        }, 600);
                      }}
                      value={this.state.doorbell}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="dropSmartlock">
                  <Form.Label column sm="6">
                    Smart lock:
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      as="select"
                      name="smartlock"
                      onChange={(e) => {
                        this.handleChange(e);
                        setTimeout(() => {
                          this.planCalculation(
                            this.state.smartHomeQuotePlanType,
                            this.state.isMember,
                            this.state.smartHomeQuoteInstallType
                          );
                        }, 600);
                      }}
                      value={this.state.smartlock}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Col className="aaa-rectangle" sm="12">
                <div className="aaa-insurance">
                  <Col sm="6" className="aaa-title">
                    <h4>Insurance</h4>
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    Annual
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    Monthly
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    <span
                      style={{
                        display: this.state.showDownpayment ? "" : "none",
                      }}
                    >
                      Down Payment
                    </span>
                  </Col>
                </div>
                <Form.Group as={Row}>
                  <Form.Label
                    className="title-margin"
                    column
                    xl="4"
                    md="6"
                    sm="4"
                  >
                    Current AAA Insurance Customer?
                  </Form.Label>
                  <Col xl="6" md="6" sm="8" className="aaa-radio radio-margin">
                    <Form.Check
                      type="radio"
                      label="Yes"
                      value="Yes"
                      name="isCurrentAAAInsuranceCustomer"
                      onChange={(e) => this.handleChange(e, constant.AAAMember)}
                      checked={
                        this.state.isCurrentAAAInsuranceCustomer === "Yes"
                      }
                    />
                    <Form.Check
                      type="radio"
                      value="No"
                      label="No"
                      name="isCurrentAAAInsuranceCustomer"
                      onChange={(e) => this.handleChange(e, constant.AAAMember)}
                      checked={
                        this.state.isCurrentAAAInsuranceCustomer === "No"
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="title-margin" column sm="6">
                    Auto Insurance Quote{" "}
                    <span className="subtext">
                      All Discounts included except MPD
                    </span>
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Annual
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        <CurrencyInput
                          id="cAutoInsuranceQuoteAnnual"
                          name="autoInsuranceQuote"
                          prefix={constant.Currency}
                          decimalsLimit={2}
                          className="form-control"
                          onValueChange={(value, name) =>
                            this.insuranceClaculation(
                              value,
                              constant.AutoInsurance
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Monthly
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.autoInsuranceQuoteMonthly
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm="2"
                    className="col-margin"
                    style={{
                      display: this.state.showDownpayment ? "" : "none",
                    }}
                  >
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Down Payment
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.autoInsuranceQuoteDownpayment
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="title-margin" column sm="6">
                    Home Insurance Quote{" "}
                    <span className="subtext">
                      All Discounts included except MPD &amp; A3SH
                    </span>
                    <span className="req">*</span>
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Annual
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        <CurrencyInput
                          id="cHomeInsuranceQuoteAnnual"
                          name="homeInsuranceQuote"
                          prefix={constant.Currency}
                          decimalsLimit={2}
                          className="form-control"
                          onValueChange={(value, name) =>
                            this.insuranceClaculation(
                              value,
                              constant.HomeInsurance
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Monthly
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.homeInsuranceQuoteMonthly
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    sm="2"
                    className="col-margin"
                    style={{
                      display: this.state.showDownpayment ? "" : "none",
                    }}
                  >
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Down Payment
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.homeInsuranceQuoteDownpayment
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Col className="aaa-rectangle" sm="12">
                <div className="aaa-insurance">
                  <Col sm="4" className="aaa-title">
                    <h4>Smart Home Security</h4>
                  </Col>

                  <Col sm="2" className="aaa-sub-title text-center">
                    Plan Type
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    Type
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    Monitoring
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-center">
                    Equipment & Install
                  </Col>
                </div>
                <Form.Group as={Row}>
                  <Form.Label className="title-margin" column sm="4">
                    Smart Home Quote<span className="req">*</span>
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Plan Type
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        <Form.Control
                          as="select"
                          name="smartHomeQuotePlanType"
                          className="form-control"
                          value={this.state.smartHomeQuotePlanType}
                          onChange={(e) =>
                            this.handleChange(e, constant.PlanType)
                          }
                          disabled={
                            this.state.doorbell > 0 ||
                            this.state.indoorcam > 0 ||
                            this.state.outdoorcam > 0 ||
                            this.state.smartlock > 0
                          }
                        >
                          <option value=""></option>
                          <option value="BASIC">BASIC</option>
                          <option value="SMART">SMART</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Type
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        <Form.Control
                          as="select"
                          name="smartHomeQuoteInstallType"
                          className="form-control"
                          value={this.state.smartHomeQuoteInstallType}
                          onChange={(e) => this.handleChange(e)}
                        >
                          <option value=""></option>
                          <option value="PRO">PRO</option>
                          <option value="SELF">SELF</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Monitoring
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.smartHomeQuoteMonitoring
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Installation
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center">
                        {this.attachCurrency(
                          this.state.smartHomeQuoteInstallation
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Col className="aaa-footer-rectangle" sm="12">
                <div className="aaa-insurance">
                  <Col sm="4" className="aaa-title">
                    <h4>Package Price</h4>
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-right">
                    List Price
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-right">
                    Bundle
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-right">
                    Security
                  </Col>
                  <Col sm="2" className="aaa-sub-title text-right">
                    Net Price
                  </Col>
                </div>
                <Form.Group as={Row}>
                  <Form.Label className="info title-margin" column sm="4">
                    Auto Insurance{" "}
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.autoInsuranceQuoteMonthly
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right red">
                        -
                        {this.state.autoInsuranceQuote !== ""
                          ? this.attachCurrency(this.state.autoInsuranceBundle)
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          Number(
                            this.state.autoInsuranceQuoteMonthly -
                              this.state.autoInsuranceBundle
                          )
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label className="info title-margin" column sm="4">
                    Home Insurance{" "}
                    {/* <span className="infoimg">
                      <img alt="" src={infoImg}></img>
                    </span> */}
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.homeInsuranceQuoteMonthly
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right red">
                        -
                        {this.state.autoInsuranceQuote !== ""
                          ? this.attachCurrency(this.state.homeInsuranceBundle)
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right red">
                        -
                        {this.state.autoInsuranceQuote !== ""
                          ? this.attachCurrency(
                              this.state.homeInsuranceSecurity
                            )
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          Number(
                            this.state.homeInsuranceQuoteMonthly -
                              this.state.homeInsuranceBundle -
                              this.state.homeInsuranceSecurity
                          )
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="info title-margin" column sm="4">
                    Smart Home Security Monitoring{" "}
                    {/* <span className="infoimg">
                      <img alt="" src={infoImg}></img>
                    </span> */}
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.smartHomeQuoteMonitoring
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.smartHomeQuoteMonitoring
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label className="info title-margin" column sm="4">
                    Home Security Equipment &amp; Installation (1st Year)
                    {/* <span className="infoimg">
                      <img alt="Info" src={infoImg}></img>
                    </span> */}
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.homeSecurityEquipmentAndInsta
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {this.attachCurrency(
                          this.state.homeSecurityEquipmentAndInsta
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="total-cost">
                  <Form.Label className="aaa-total title-margin" column sm="4">
                    <h4>Monthly Total Cost</h4>
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right">
                        {/* {this.attachCurrency(
                          this.state.monthlyTotalCostListPrice
                        )} */}

                        {this.attachCurrency(
                          Number(this.state.autoInsuranceQuoteMonthly) +
                            Number(this.state.homeInsuranceQuoteMonthly) +
                            Number(this.state.smartHomeQuoteMonitoring) +
                            Number(this.state.homeSecurityEquipmentAndInsta)
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col sm="4" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-center red ml-5">
                        -
                        {this.state.autoInsuranceQuote !== ""
                          ? this.attachCurrency(this.state.monthlyTotalDiscount)
                          : ""}
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col> */}
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="aaa-total text-right">
                        <h4>
                          {" "}
                          {this.attachCurrency(
                            Number(
                              this.state.autoInsuranceQuoteMonthly -
                                this.state.autoInsuranceBundle
                            ) +
                              Number(
                                this.state.homeInsuranceQuoteMonthly -
                                  this.state.homeInsuranceBundle -
                                  this.state.homeInsuranceSecurity
                              ) +
                              Number(this.state.homeSecurityEquipmentAndInsta) +
                              Number(this.state.smartHomeQuoteMonitoring)
                          )}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="total-cost annualsaving">
                  <Form.Label className="aaa-total title-margin" column sm="4">
                    <h4>Annual Savings</h4>
                  </Form.Label>
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          List Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col>
                  <Col sm="4" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Bundle
                        </Form.Label>
                      </Col>
                      <Col
                        sm="12"
                        xs="5"
                        className="text-center red ml-5"
                      ></Col>
                    </Row>
                  </Col>
                  {/* <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Security
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="text-right"></Col>
                    </Row>
                  </Col> */}
                  <Col sm="2" className="col-margin">
                    <Row className="price">
                      <Col sm="3" xs="7" className="d-xs-block d-sm-none">
                        <Form.Label column sm="6">
                          Net Price
                        </Form.Label>
                      </Col>
                      <Col sm="12" xs="5" className="aaa-total text-right">
                        <h4>
                          {" "}
                          {this.state.autoInsuranceQuote !== ""
                            ? this.attachCurrency(
                                Math.round(this.state.monthlyTotalDiscount * 12)
                              )
                            : ""}
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Col>
            </Col>
          </Row>

          <div className="submit-buttons">
            <Button
              variant="primary"
              disabled={this.state.Downloading === constant.Downloading}
              onClick={(e) => this.pdfjs(e)}
            >
              {this.state.Downloading}
            </Button>{" "}
            <Button
              variant="primary"
              disabled={this.state.Emailsent === constant.Sendingmail}
              onClick={(e) => this.sendMail(e)}
            >
              {this.state.Emailsent}
            </Button>{" "}
          </div>
          <div id="errormessege" className="text-center hidden">
            {" "}
            <span className="req"> {"*"} Fill all mandatory fields </span>
          </div>
          <Row>
            <Col xl={12} lg={12} md={12} xs={12}>
              <div className="hidden">
                <div id="page">
                  <PDF props={this.state}></PDF>
                </div>
                <div id="emailTemplate">
                  <EmailSupport></EmailSupport>
                  {/* <EmailTemplate props={this.state}></EmailTemplate> */}
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </section>
    );
  }
}
