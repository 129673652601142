import React from "react";
import { Table } from "react-bootstrap";
import "../PDFComponent/PDF.css";
import logo from "../../static/images/aaa-logo.svg";
import hero from "../../static/images/hero-image.jpg";
import check from "../../static/images/check.inline.svg";
import { Currency } from "../../constants";

export default class DownloadPDF extends React.Component {
  constructor(props) {
    super();
    this.state = {
      pdf: props.props,
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      pdf: nextProps.props,
    };
  }
  //Add currency Symbol to amount
  attachCurrency(amount) {
    if (amount !== "" && amount !== 0 && amount !== undefined)
      return Currency + Number(amount).toFixed(2);
  }
  render() {
    return (
      <div className="page" data-size="A4">
        <div className="header__logo text-right">
          <img alt="A3 Smart Home" src={logo} />
        </div>
        <div className="PDF-heading text-center">
          <h2>Bundle and Save with AAA</h2>
          <p>
            Estimate how much you save when you bundle AAA Smart Home &amp; Home
            Insurance
          </p>
        </div>
        <div className="PDF-hero">
          <img alt="A3 Smart Home" src={hero} />
          <div className="hero-text text-center">
            <h4>Your estimated insurance savings :</h4>
            <div className="cta">
              {this.attachCurrency(
                Math.round(this.state.pdf.monthlyTotalDiscount * 12)
              )}{" "}
              <span>per year*</span>
            </div>
            <h5 className="Total">
              Plus, you may be eligible for <span>Total Protection</span>. We
              reimburse your deductible - upto $1,000 - after a qualifying loss.
            </h5>
            <p>
              Restrictions apply. Only policies underwritten by CSAA Insurance
              Group and sold by AAA Northern California, Nevada &amp; Utah
              qualify for deductible reimbursement. Fire losses indicated as
              wildfire events excluded from program. Must have AAA Smart
              Home-monitored smoke detector to qualify for reimbursements for
              other fire-related claims. See full program terms at
              aaa.com/smarthometotalprotection.
            </p>
          </div>
        </div>
        <Table striped hover size="sm">
          <thead>
            <tr>
              <th>INSURANCE*</th>
              <th className="text-center">Annual</th>
              <th className="text-center">Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Auto Insurance Quote{" "}
                <span className="txt-italic">
                  (All discounts included except MPD)
                </span>
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.autoInsuranceQuote)}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.autoInsuranceQuoteMonthly)}
              </td>
            </tr>
            <tr>
              <td>
                Home Insurance Quote{" "}
                <span className="txt-italic">
                  (All discounts included except MPD &amp; A3SH)
                </span>
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.homeInsuranceQuote)}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.homeInsuranceQuoteMonthly)}
              </td>
            </tr>
          </tbody>
        </Table>

        <Table striped hover size="sm">
          <thead>
            <tr>
              <th className="pdf-security align-middle">SMART HOME SECURITY</th>
              <th className="text-center align-middle">AAA Member</th>
              <th className="text-center align-middle">Plan Type</th>
              <th className="text-center align-middle">Monthly Monitoring</th>
              <th className="text-center align-middle">
                Equipment and Installation
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Smart Home Quote</td>
              <td className="text-center">{this.state.pdf.isMember}</td>
              <td className="text-center">
                {this.state.pdf.smartHomeQuotePlanType}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.smartHomeQuoteMonitoring)}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.smartHomeQuoteInstallation)}
              </td>
            </tr>
          </tbody>
        </Table>

        <Table striped hover size="sm">
          <thead>
            <tr>
              <th className="pdf-pricing align-middle">Package Pricing</th>
              <th className="text-center align-middle">Monthly Price List</th>
              <th className="text-center align-middle">
                Auto/Home Bundle Discount
              </th>
              <th className="text-center align-middle">
                Smart Home Security Discount
              </th>
              <th className="text-center align-middle">Monthly Net Price</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Auto Insurance</td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.autoInsuranceQuoteMonthly)}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.autoInsuranceBundle)}
              </td>
              <td></td>
              <td className="text-center">
                {this.attachCurrency(
                  Number(
                    this.state.pdf.autoInsuranceQuoteMonthly -
                      this.state.pdf.autoInsuranceBundle
                  )
                )}
              </td>
            </tr>
            <tr>
              <td>Home Insurance</td>
              <td className="text-center">
                {" "}
                {this.attachCurrency(this.state.pdf.homeInsuranceQuoteMonthly)}
              </td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.homeInsuranceBundle)}
              </td>
              <td>
                {this.attachCurrency(this.state.pdf.homeInsuranceSecurity)}
              </td>
              <td className="text-center">
                {" "}
                {this.attachCurrency(
                  Number(
                    this.state.pdf.homeInsuranceQuoteMonthly -
                      this.state.pdf.homeInsuranceBundle -
                      this.state.pdf.homeInsuranceSecurity
                  )
                )}
              </td>
            </tr>
            <tr>
              <td>Smart Home Security Monitoring</td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.smartHomeQuoteMonitoring)}
              </td>
              <td></td>
              <td></td>
              <td className="text-center">
                {this.attachCurrency(this.state.pdf.smartHomeQuoteMonitoring)}
              </td>
            </tr>
            <tr>
              <td>
                Home Security Equipment &amp; Installation{" "}
                <span className="txt-italic">(First Year)</span>
              </td>
              <td className="text-center">
                {this.attachCurrency(
                  this.state.pdf.homeSecurityEquipmentAndInsta
                )}
              </td>
              <td></td>
              <td></td>
              <td className="text-center">
                {this.attachCurrency(
                  this.state.pdf.homeSecurityEquipmentAndInsta
                )}
              </td>
            </tr>
          </tbody>
        </Table>

        <Table striped hover size="sm">
          <thead>
            <tr>
              <th colSpan="5" className="pdf-security align-middle">
                Monthly Cost
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pdf-discription">List Price</td>
              <td className="text-right">
                {this.attachCurrency(
                  Number(this.state.pdf.autoInsuranceQuoteMonthly) +
                    Number(this.state.pdf.homeInsuranceQuoteMonthly) +
                    Number(this.state.pdf.smartHomeQuoteMonitoring) +
                    Number(this.state.pdf.homeSecurityEquipmentAndInsta)
                )}
              </td>
            </tr>

            <tr>
              <td className="pdf-discription">Bundle Discounts</td>
              <td className="text-right">
                {this.attachCurrency(this.state.pdf.monthlyTotalDiscount)}
              </td>
            </tr>

            <tr>
              <td className="pdf-discription">Net Monthly Cost</td>
              <td className="text-right">
                {this.attachCurrency(
                  Number(
                    this.state.pdf.autoInsuranceQuoteMonthly -
                      this.state.pdf.autoInsuranceBundle
                  ) +
                    Number(
                      this.state.pdf.homeInsuranceQuoteMonthly -
                        this.state.pdf.homeInsuranceBundle -
                        this.state.pdf.homeInsuranceSecurity
                    ) +
                    Number(this.state.pdf.homeSecurityEquipmentAndInsta) +
                    Number(this.state.pdf.smartHomeQuoteMonitoring)
                )}
              </td>
            </tr>
          </tbody>
        </Table>
        <footer>
          <div className="PDF-footer">
            <div className="footer-text">
              <h3>Don't overpay for home security.</h3>
              <h3>
                Experience{" "}
                <span className="title-red">Home Security, the AAA Way.</span>
              </h3>
              <div className="assistentsection">
                <div className="section-text">
                  <img alt="A3 Smart Home" src={check} />
                  <h5>
                    Advanced Protection, Assistance 24/7 &amp; AAA Assurance
                  </h5>
                </div>
                <div className="section-text">
                  <img alt="A3 Smart Home" src={check} />
                  <h5>
                    AAA Members save $60/year on monitoring<sup>2</sup>
                  </h5>
                </div>
                <div className="section-text">
                  <img alt="A3 Smart Home" src={check} />
                  <h5>
                    Save upto 20% on professionally monitored home security vs.
                    other brands<sup>3</sup>
                  </h5>
                </div>
              </div>
            </div>
            <div className="disclaimer">
              <p>
                1.This AAA Insurance and Smart Home bundle tool is intended for
                informational purposes only, and is not a quote for insurance
                coverage or home security monitoring. Actual monthly cost and
                estimated discounts will depend on the type of insurance
                coverage,limits and deductibles, and the specific Smart Home
                monitoring plan selected. Other factors, such as the state you
                live in and the insurance plan selected, may also affect prices.
                See agent for more details.
              </p>
              <p>
                2. Requires purchase of a new control panel. System sold
                separately. Installation or shipping fees apply.Valid AAA
                Membership required for discount. Please call 844-669-2221 for
                availability in your area. Certain terms and conditions may
                apply, visit AAA.com/SmartTerms for details.
              </p>
              <p>
                3. 20% savings is based on the monthly cost of Basic Monitoring
                plus an Essential Security Bundle, which costs 20% less than
                industry averages as reported by Parks Associates in "Home
                Security - Market Sizing and Forecasts 2019."
              </p>
              <p className="disclaimer-bold">
                Insurance products in Arizona are offered through AAA Arizona
                Inc., License #8301727 and Sunstate Insurance Agency,LLC,
                License #17203444. Insurance products in California are offered
                through AAA Northern California Insurance Agency, License
                #0175868, in Montana by AAA Montana, Inc., License #9756, in
                Nevada by AAA Nevada and in Utah by AAA Utah. The provider of
                AAA Home Insurance is CSAA Insurance Group, a AAA Insurer.
                Products and their features may not be available in all states.
                All policies are subject to policy terms, underwriting, guide
                lines and applicable laws.
              </p>
            </div>
          </div>
          <div className="footer-divider"></div>
          <div className="title-red">AAA.com/AAASmart</div>
        </footer>
      </div>
    );
  }
}
