import React from "react";

function Support() {
  return (
    <div
      style={{
        width: "700px",
        maxHeight: "1000px",
        padding: "30px",
        margin: "0 auto",
        background: "white",
      }}
    >
      <div style={{display: "none", maxHeight: "0px",overflow: "hidden"}}>
      Thank you for your interest in AAA. We're excited to show you how much you could save when you bundle with AAA.
</div>
<div style={{display: "none", maxHeight: "0px",overflow: "hidden"}}>
&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;
</div>
      {/* <p>Thank you for your interest in AAA. We're excited to show you how much you could save when you bundle with AAA.</p> */}
      <p>Dear _DearName_,</p>
      <p>Thank you for considering AAA. Attached is your savings estimate with an outline of the plans that we discussed. Don't hesitate to call or email with any questions.</p>
      <p>One of the most exciting things AAA offers is the ability to save while getting maximum protection and peace of mind. For example:</p>
      <ul>
        <li>Save 8% on your home insurance with AAA Smart Home.<sup>1</sup></li>
        <li>AAA Members save $60 on low-cost 24/7 professional monitoring.<sup>2</sup></li>
        <li>Save 20% compared to industry averages for professionally monitored home security.<sup>3</sup></li>
      </ul>
      <p>We hope you found all the information you are looking for. If you have any questions or concerns, I would welcome the chance to discuss them with you. Please feel free to contact me using the information in my signature. Thank you for choosing AAA, and have a great day.</p>
      <p style={{ margin: "0" }}>Sincerely,</p>
      <p style={{ margin: "0" }}>_AgentName_</p>
      <div style={{ fontSize: "10px", marginTop: "80px" }}>
        <p style={{ margin: "0", borderBottom: "1px solid #000" }}></p>
        <p style={{ margin: "0" }}>1. Savings vary based on state (CA 10-15%, AZ/NV/UT/MT/WY 8%)</p>
        <p style={{ margin: "0" }}>2. Discount applies to monthly alarm and smart home monitoring fees only. Requires purchase of a new control panel. System sold separately. Installation or shipping fees apply. Valid AAA Membership required for discount. Please contact us for availability in your area. Certain terms and conditions may apply, visit AAA.com/SmartTerms for details.</p>
        <p style={{ margin: "0" }}>3. 20% or more savings is based on the monthly cost of Basic Monitoring plus an Essential Security Bundle, which costs 20% less than industry averages as reported by Parks Associates in "Home Security - Market Sizing and Forecasts 2019."</p>

      </div>
      <div style={{ fontSize: "10px", marginTop: "30px" }}>
        <p><a href="https://calstate.aaa.com/privacy-policy">Privacy Policy</a> | <a href="https://smarthome.calstate.aaa.com/terms-and-conditions">Terms & Conditions</a></p>
        <p>This email was sent by A3 Smart Home LP.</p>
        <p>1277 Treat Boulevard, Suite 1000, Walnut Creek, CA 94597</p>
        <p>© 2021 A3 Smart Home LP All rights reserved.</p>
        <p>Alarm License No. CA ACO 7976; UT 10685724-6501</p>

      </div>
    </div>

  );
}
export default Support;