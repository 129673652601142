import React from "react";

import "../css/PublicPage.css";

const PublicPage = () => (
  <div className="Profile-page">
    <h3>Login Status Page</h3>
    <p>This is login status page.</p>
  </div>
);

export default PublicPage;
