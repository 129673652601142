import React from "react";
import {
  Link,
  NavLink
} from "react-router-dom";
import logo from "../static/images/aaa-smart-home-logo.svg";

import "../css/Header.css";

const Header = ({ loggedIn, logout }) => (
  <div className="header">
    <div className="content-box">
      <div className="aaa-header">
        <div className="header__logo">
          <div className="header__smart-tab">
            <a aria-current="page" className="" href="/">
              <img alt="A3 Smart Home" src={logo} />
            </a>
          </div>
        </div>
        <div className="header__title">AAA Bundle Savings Tool</div>
        <div className="header__links">
          {loggedIn
            ? (<LoggedInNav logout={logout} />)
            : (<LoggedOutNav />)
          }
        </div>
      </div>
    </div>
    <div className="divider"></div>
  </div>
);

const LoggedInNav = ({ logout }) => (
  <>
    <NavLink to="/home">Login Status</NavLink>
    <NavLink to="/profile">Profile</NavLink>
    <Link to="/home" onClick={logout}>Logout</Link>
  </>
)

const LoggedOutNav = () => (
  <>
    <NavLink to="/home">Login Status</NavLink>
    <NavLink to="/login">Login</NavLink>
  </>
);

export default Header;
