import { stringify } from "query-string";

// Static oidc params for a single provider
const authority = "https://winwire-onlogin-dev.onelogin.com/oidc/2";
const client_id = "c73d7d40-c531-0139-8097-02e73e83c4bd193248";
const redirect_uri = "https://dev.bundlesavings.calstate.aaa.com/auth";
const response_type = "id_token token";
const scope = "openid profile";

export const beginAuth = ({ state, nonce }) => {
  // Generate authentication URL
  const params = stringify({
    client_id,
    redirect_uri,
    response_type,
    scope,
    state,
    nonce
  });
  const authUrl = `${authority}/auth?${params}`;
  console.log(authUrl);

  // Attempt login by navigating to authUrl
  window.location.assign(authUrl);
};
